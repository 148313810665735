import { IconProps } from '../types'

export const SeedlingIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 128 128" {...props}>
      <path
        fill={props.color || '#99c23b'}
        d="m67.47 48.2l-1.11 18.48s3.76 10.64 2.71 25.45c-1.01 14.27-4.8 21.49-5.84 23.99s-3.96 5.63 0 6.88s10.64.63 13.56-.42c2.92-1.04 4.37-6.84 4.8-9.18c1.05-5.79 1.63-16.74-.5-27.54C79.46 77.56 76 63.33 76 63.33z"
      />
      <path
        fill={props.color || '#99c23b'}
        d="M66.78 45.4s4.28-13.43 14.12-23.62c9.43-9.76 19.06-12.96 26.78-13.92c8.51-1.06 11.98-.95 13.44.1s.94 2.7.97 4.16c.19 10.44.58 23.97-11.7 36.35c-13.11 13.2-22.45 15.57-32.68 16.62c-10.22 1.04-9.06-3.62-9.06-3.62z"
      />
      <path
        fill={props.color || '#99c23b'}
        d="M2.98 37.05c-.37 1.83 9.29 23.31 27.95 30.07c20.09 7.29 39.03 0 39.03 0l1.56-19.34l-4.75-2.38S56.7 34.29 45.51 30.19c-11.6-4.25-24.71-4.58-33.64.19c-5.6 3-8.69 5.7-8.89 6.67"
      />
      <path
        fill="#e6dc9f"
        d="M86.68 30.68C77.38 41.7 72.69 52.81 72.2 55.71s1.12 2.78 2.03 1.55c1.64-2.22 7.35-12.37 15.85-22.04c7.36-8.36 18.56-17.4 20.2-19.33c1.02-1.2-.1-2.71-1.64-2.03c-1.54.67-12.45 5.55-21.96 16.82M41.43 41c-13.6-4.68-23.55-4.05-25.58-3.37s-1.93 2.9.48 3.19s12.24 1.27 24.56 5.7c13.15 4.74 22.52 11.89 24.17 12.86c1.64.97 2.71-.97 1.26-2.71c-1.11-1.31-9.89-10.51-24.89-15.67"
      />
    </svg>
  )
}
