export const PERMISSIONS = {
  administration: {
    all_actions: 'administration.all_actions',
  },
  habit: {
    view: 'habit.view',
    create: 'habit.create',
    update: 'habit.update',
    delete: 'habit.delete',
  },
  habit_check_in: {
    save: 'habit_check_in.save',
    delete: 'habit_check_in.delete',
  },
  dashboard: {
    view: 'dashboard.view',
  },
  profile: {
    save: 'profile.save',
  },
}
