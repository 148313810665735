// Label component extends from shadcnui - https://ui.shadcn.com/docs/components/label

'use client'
import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'

import { cn } from '@ui/utils'

const AceLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(
      'text-sm font-medium text-black dark:text-white leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
      className
    )}
    {...props}
  />
))
AceLabel.displayName = LabelPrimitive.Root.displayName

export { AceLabel }
