export const baseColors = [
  {
    name: 'zinc',
    label: 'Zinc',
    activeColor: {
      light: '240 5.9% 10%',
      dark: '240 5.2% 33.9%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '240 10% 3.9%',
        card: '0 0% 100%',
        'card-foreground': '240 10% 3.9%',
        popover: '0 0% 100%',
        'popover-foreground': '240 10% 3.9%',
        primary: '240 5.9% 10%',
        'primary-foreground': '0 0% 98%',
        secondary: '240 4.8% 95.9%',
        'secondary-foreground': '240 5.9% 10%',
        muted: '240 4.8% 95.9%',
        'muted-foreground': '240 3.8% 46.1%',
        accent: '240 4.8% 95.9%',
        'accent-foreground': '240 5.9% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '0 0% 98%',
        border: '240 5.9% 90%',
        input: '240 5.9% 90%',
        ring: '240 5.9% 10%',
        radius: '0.5rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '240 10% 3.9%',
        foreground: '0 0% 98%',
        card: '240 10% 3.9%',
        'card-foreground': '0 0% 98%',
        popover: '240 10% 3.9%',
        'popover-foreground': '0 0% 98%',
        primary: '0 0% 98%',
        'primary-foreground': '240 5.9% 10%',
        secondary: '240 3.7% 15.9%',
        'secondary-foreground': '0 0% 98%',
        muted: '240 3.7% 15.9%',
        'muted-foreground': '240 5% 64.9%',
        accent: '240 3.7% 15.9%',
        'accent-foreground': '0 0% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '0 0% 98%',
        border: '240 3.7% 15.9%',
        input: '240 3.7% 15.9%',
        ring: '240 4.9% 83.9%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'slate',
    label: 'Slate',
    activeColor: {
      light: '215.4 16.3% 46.9%',
      dark: '215.3 19.3% 34.5%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '222.2 84% 4.9%',
        card: '0 0% 100%',
        'card-foreground': '222.2 84% 4.9%',
        popover: '0 0% 100%',
        'popover-foreground': '222.2 84% 4.9%',
        primary: '222.2 47.4% 11.2%',
        'primary-foreground': '210 40% 98%',
        secondary: '210 40% 96.1%',
        'secondary-foreground': '222.2 47.4% 11.2%',
        muted: '210 40% 96.1%',
        'muted-foreground': '215.4 16.3% 46.9%',
        accent: '210 40% 96.1%',
        'accent-foreground': '222.2 47.4% 11.2%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '210 40% 98%',
        border: '214.3 31.8% 91.4%',
        input: '214.3 31.8% 91.4%',
        ring: '222.2 84% 4.9%',
        radius: '0.5rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '222.2 84% 4.9%',
        foreground: '210 40% 98%',
        card: '222.2 84% 4.9%',
        'card-foreground': '210 40% 98%',
        popover: '222.2 84% 4.9%',
        'popover-foreground': '210 40% 98%',
        primary: '210 40% 98%',
        'primary-foreground': '222.2 47.4% 11.2%',
        secondary: '217.2 32.6% 17.5%',
        'secondary-foreground': '210 40% 98%',
        muted: '217.2 32.6% 17.5%',
        'muted-foreground': '215 20.2% 65.1%',
        accent: '217.2 32.6% 17.5%',
        'accent-foreground': '210 40% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '210 40% 98%',
        border: '217.2 32.6% 17.5%',
        input: '217.2 32.6% 17.5%',
        ring: '212.7 26.8% 83.9',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'stone',
    label: 'Stone',
    activeColor: {
      light: '25 5.3% 44.7%',
      dark: '33.3 5.5% 32.4%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '20 14.3% 4.1%',
        card: '0 0% 100%',
        'card-foreground': '20 14.3% 4.1%',
        popover: '0 0% 100%',
        'popover-foreground': '20 14.3% 4.1%',
        primary: '24 9.8% 10%',
        'primary-foreground': '60 9.1% 97.8%',
        secondary: '60 4.8% 95.9%',
        'secondary-foreground': '24 9.8% 10%',
        muted: '60 4.8% 95.9%',
        'muted-foreground': '25 5.3% 44.7%',
        accent: '60 4.8% 95.9%',
        'accent-foreground': '24 9.8% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '20 5.9% 90%',
        input: '20 5.9% 90%',
        ring: '20 14.3% 4.1%',
        radius: '0.95rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '20 14.3% 4.1%',
        foreground: '60 9.1% 97.8%',
        card: '20 14.3% 4.1%',
        'card-foreground': '60 9.1% 97.8%',
        popover: '20 14.3% 4.1%',
        'popover-foreground': '60 9.1% 97.8%',
        primary: '60 9.1% 97.8%',
        'primary-foreground': '24 9.8% 10%',
        secondary: '12 6.5% 15.1%',
        'secondary-foreground': '60 9.1% 97.8%',
        muted: '12 6.5% 15.1%',
        'muted-foreground': '24 5.4% 63.9%',
        accent: '12 6.5% 15.1%',
        'accent-foreground': '60 9.1% 97.8%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '12 6.5% 15.1%',
        input: '12 6.5% 15.1%',
        ring: '24 5.7% 82.9%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'gray',
    label: 'Gray',
    activeColor: {
      light: '220 8.9% 46.1%',
      dark: '215 13.8% 34.1%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '224 71.4% 4.1%',
        card: '0 0% 100%',
        'card-foreground': '224 71.4% 4.1%',
        popover: '0 0% 100%',
        'popover-foreground': '224 71.4% 4.1%',
        primary: '220.9 39.3% 11%',
        'primary-foreground': '210 20% 98%',
        secondary: '220 14.3% 95.9%',
        'secondary-foreground': '220.9 39.3% 11%',
        muted: '220 14.3% 95.9%',
        'muted-foreground': '220 8.9% 46.1%',
        accent: '220 14.3% 95.9%',
        'accent-foreground': '220.9 39.3% 11%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '210 20% 98%',
        border: '220 13% 91%',
        input: '220 13% 91%',
        ring: '224 71.4% 4.1%',
        radius: '0.35rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '224 71.4% 4.1%',
        foreground: '210 20% 98%',
        card: '224 71.4% 4.1%',
        'card-foreground': '210 20% 98%',
        popover: '224 71.4% 4.1%',
        'popover-foreground': '210 20% 98%',
        primary: '210 20% 98%',
        'primary-foreground': '220.9 39.3% 11%',
        secondary: '215 27.9% 16.9%',
        'secondary-foreground': '210 20% 98%',
        muted: '215 27.9% 16.9%',
        'muted-foreground': '217.9 10.6% 64.9%',
        accent: '215 27.9% 16.9%',
        'accent-foreground': '210 20% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '210 20% 98%',
        border: '215 27.9% 16.9%',
        input: '215 27.9% 16.9%',
        ring: '216 12.2% 83.9%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'neutral',
    label: 'Neutral',
    activeColor: {
      light: '0 0% 45.1%',
      dark: '0 0% 32.2%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '0 0% 3.9%',
        card: '0 0% 100%',
        'card-foreground': '0 0% 3.9%',
        popover: '0 0% 100%',
        'popover-foreground': '0 0% 3.9%',
        primary: '0 0% 9%',
        'primary-foreground': '0 0% 98%',
        secondary: '0 0% 96.1%',
        'secondary-foreground': '0 0% 9%',
        muted: '0 0% 96.1%',
        'muted-foreground': '0 0% 45.1%',
        accent: '0 0% 96.1%',
        'accent-foreground': '0 0% 9%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '0 0% 98%',
        border: '0 0% 89.8%',
        input: '0 0% 89.8%',
        ring: '0 0% 3.9%',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '0 0% 3.9%',
        foreground: '0 0% 98%',
        card: '0 0% 3.9%',
        'card-foreground': '0 0% 98%',
        popover: '0 0% 3.9%',
        'popover-foreground': '0 0% 98%',
        primary: '0 0% 98%',
        'primary-foreground': '0 0% 9%',
        secondary: '0 0% 14.9%',
        'secondary-foreground': '0 0% 98%',
        muted: '0 0% 14.9%',
        'muted-foreground': '0 0% 63.9%',
        accent: '0 0% 14.9%',
        'accent-foreground': '0 0% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '0 0% 98%',
        border: '0 0% 14.9%',
        input: '0 0% 14.9%',
        ring: '0 0% 83.1%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'red',
    label: 'Red',
    activeColor: {
      light: '0 72.2% 50.6%',
      dark: '0 72.2% 50.6%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '0 0% 3.9%',
        card: '0 0% 100%',
        'card-foreground': '0 0% 3.9%',
        popover: '0 0% 100%',
        'popover-foreground': '0 0% 3.9%',
        primary: '0 72.2% 50.6%',
        'primary-foreground': '0 85.7% 97.3%',
        secondary: '0 0% 96.1%',
        'secondary-foreground': '0 0% 9%',
        muted: '0 0% 96.1%',
        'muted-foreground': '0 0% 45.1%',
        accent: '0 0% 96.1%',
        'accent-foreground': '0 0% 9%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '0 0% 98%',
        border: '0 0% 89.8%',
        input: '0 0% 89.8%',
        ring: '0 72.2% 50.6%',
        radius: '0.4rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '0 0% 3.9%',
        foreground: '0 0% 98%',
        card: '0 0% 3.9%',
        'card-foreground': '0 0% 98%',
        popover: '0 0% 3.9%',
        'popover-foreground': '0 0% 98%',
        primary: '0 72.2% 50.6%',
        'primary-foreground': '0 85.7% 97.3%',
        secondary: '0 0% 14.9%',
        'secondary-foreground': '0 0% 98%',
        muted: '0 0% 14.9%',
        'muted-foreground': '0 0% 63.9%',
        accent: '0 0% 14.9%',
        'accent-foreground': '0 0% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '0 0% 98%',
        border: '0 0% 14.9%',
        input: '0 0% 14.9%',
        ring: '0 72.2% 50.6%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'rose',
    label: 'Rose',
    activeColor: {
      light: '346.8 77.2% 49.8%',
      dark: '346.8 77.2% 49.8%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '240 10% 3.9%',
        card: '0 0% 100%',
        'card-foreground': '240 10% 3.9%',
        popover: '0 0% 100%',
        'popover-foreground': '240 10% 3.9%',
        primary: '346.8 77.2% 49.8%',
        'primary-foreground': '355.7 100% 97.3%',
        secondary: '240 4.8% 95.9%',
        'secondary-foreground': '240 5.9% 10%',
        muted: '240 4.8% 95.9%',
        'muted-foreground': '240 3.8% 46.1%',
        accent: '240 4.8% 95.9%',
        'accent-foreground': '240 5.9% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '0 0% 98%',
        border: '240 5.9% 90%',
        input: '240 5.9% 90%',
        ring: '346.8 77.2% 49.8%',
        radius: '0.5rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '20 14.3% 4.1%',
        foreground: '0 0% 95%',
        popover: '0 0% 9%',
        'popover-foreground': '0 0% 95%',
        card: '24 9.8% 10%',
        'card-foreground': '0 0% 95%',
        primary: '346.8 77.2% 49.8%',
        'primary-foreground': '355.7 100% 97.3%',
        secondary: '240 3.7% 15.9%',
        'secondary-foreground': '0 0% 98%',
        muted: '0 0% 15%',
        'muted-foreground': '240 5% 64.9%',
        accent: '12 6.5% 15.1%',
        'accent-foreground': '0 0% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '0 85.7% 97.3%',
        border: '240 3.7% 15.9%',
        input: '240 3.7% 15.9%',
        ring: '346.8 77.2% 49.8%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'orange',
    label: 'Orange',
    activeColor: {
      light: '24.6 95% 53.1%',
      dark: '20.5 90.2% 48.2%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '20 14.3% 4.1%',
        card: '0 0% 100%',
        'card-foreground': '20 14.3% 4.1%',
        popover: '0 0% 100%',
        'popover-foreground': '20 14.3% 4.1%',
        primary: '24.6 95% 53.1%',
        'primary-foreground': '60 9.1% 97.8%',
        secondary: '60 4.8% 95.9%',
        'secondary-foreground': '24 9.8% 10%',
        muted: '60 4.8% 95.9%',
        'muted-foreground': '25 5.3% 44.7%',
        accent: '60 4.8% 95.9%',
        'accent-foreground': '24 9.8% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '20 5.9% 90%',
        input: '20 5.9% 90%',
        ring: '24.6 95% 53.1%',
        radius: '0.95rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '20 14.3% 4.1%',
        foreground: '60 9.1% 97.8%',
        card: '20 14.3% 4.1%',
        'card-foreground': '60 9.1% 97.8%',
        popover: '20 14.3% 4.1%',
        'popover-foreground': '60 9.1% 97.8%',
        primary: '20.5 90.2% 48.2%',
        'primary-foreground': '60 9.1% 97.8%',
        secondary: '12 6.5% 15.1%',
        'secondary-foreground': '60 9.1% 97.8%',
        muted: '12 6.5% 15.1%',
        'muted-foreground': '24 5.4% 63.9%',
        accent: '12 6.5% 15.1%',
        'accent-foreground': '60 9.1% 97.8%',
        destructive: '0 72.2% 50.6%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '12 6.5% 15.1%',
        input: '12 6.5% 15.1%',
        ring: '20.5 90.2% 48.2%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'green',
    label: 'Green',
    activeColor: {
      light: '142.1 76.2% 36.3%',
      dark: '142.1 70.6% 45.3%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '240 10% 3.9%',
        card: '0 0% 100%',
        'card-foreground': '240 10% 3.9%',
        popover: '0 0% 100%',
        'popover-foreground': '240 10% 3.9%',
        primary: '142.1 76.2% 36.3%',
        'primary-foreground': '355.7 100% 97.3%',
        secondary: '240 4.8% 95.9%',
        'secondary-foreground': '240 5.9% 10%',
        muted: '240 4.8% 95.9%',
        'muted-foreground': '240 3.8% 46.1%',
        accent: '240 4.8% 95.9%',
        'accent-foreground': '240 5.9% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '0 0% 98%',
        border: '240 5.9% 90%',
        input: '240 5.9% 90%',
        ring: '142.1 76.2% 36.3%',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '20 14.3% 4.1%',
        foreground: '0 0% 95%',
        popover: '0 0% 9%',
        'popover-foreground': '0 0% 95%',
        card: '24 9.8% 10%',
        'card-foreground': '0 0% 95%',
        primary: '142.1 70.6% 45.3%',
        'primary-foreground': '144.9 80.4% 10%',
        secondary: '240 3.7% 15.9%',
        'secondary-foreground': '0 0% 98%',
        muted: '0 0% 15%',
        'muted-foreground': '240 5% 64.9%',
        accent: '12 6.5% 15.1%',
        'accent-foreground': '0 0% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '0 85.7% 97.3%',
        border: '240 3.7% 15.9%',
        input: '240 3.7% 15.9%',
        ring: '142.4 71.8% 29.2%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'blue',
    label: 'Blue',
    activeColor: {
      light: '221.2 83.2% 53.3%',
      dark: '217.2 91.2% 59.8%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '222.2 84% 4.9%',
        card: '0 0% 100%',
        'card-foreground': '222.2 84% 4.9%',
        popover: '0 0% 100%',
        'popover-foreground': '222.2 84% 4.9%',
        primary: '221.2 83.2% 53.3%',
        'primary-foreground': '210 40% 98%',
        secondary: '210 40% 96.1%',
        'secondary-foreground': '222.2 47.4% 11.2%',
        muted: '210 40% 96.1%',
        'muted-foreground': '215.4 16.3% 46.9%',
        accent: '210 40% 96.1%',
        'accent-foreground': '222.2 47.4% 11.2%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '210 40% 98%',
        border: '214.3 31.8% 91.4%',
        input: '214.3 31.8% 91.4%',
        ring: '221.2 83.2% 53.3%',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '222.2 84% 4.9%',
        foreground: '210 40% 98%',
        card: '222.2 84% 4.9%',
        'card-foreground': '210 40% 98%',
        popover: '222.2 84% 4.9%',
        'popover-foreground': '210 40% 98%',
        primary: '217.2 91.2% 59.8%',
        'primary-foreground': '222.2 47.4% 11.2%',
        secondary: '217.2 32.6% 17.5%',
        'secondary-foreground': '210 40% 98%',
        muted: '217.2 32.6% 17.5%',
        'muted-foreground': '215 20.2% 65.1%',
        accent: '217.2 32.6% 17.5%',
        'accent-foreground': '210 40% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '210 40% 98%',
        border: '217.2 32.6% 17.5%',
        input: '217.2 32.6% 17.5%',
        ring: '224.3 76.3% 48%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'yellow',
    label: 'Yellow',
    activeColor: {
      light: '47.9 95.8% 53.1%',
      dark: '47.9 95.8% 53.1%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '20 14.3% 4.1%',
        card: '0 0% 100%',
        'card-foreground': '20 14.3% 4.1%',
        popover: '0 0% 100%',
        'popover-foreground': '20 14.3% 4.1%',
        primary: '47.9 95.8% 53.1%',
        'primary-foreground': '26 83.3% 14.1%',
        secondary: '60 4.8% 95.9%',
        'secondary-foreground': '24 9.8% 10%',
        muted: '60 4.8% 95.9%',
        'muted-foreground': '25 5.3% 44.7%',
        accent: '60 4.8% 95.9%',
        'accent-foreground': '24 9.8% 10%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '20 5.9% 90%',
        input: '20 5.9% 90%',
        ring: '20 14.3% 4.1%',
        radius: '0.95rem',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '20 14.3% 4.1%',
        foreground: '60 9.1% 97.8%',
        card: '20 14.3% 4.1%',
        'card-foreground': '60 9.1% 97.8%',
        popover: '20 14.3% 4.1%',
        'popover-foreground': '60 9.1% 97.8%',
        primary: '47.9 95.8% 53.1%',
        'primary-foreground': '26 83.3% 14.1%',
        secondary: '12 6.5% 15.1%',
        'secondary-foreground': '60 9.1% 97.8%',
        muted: '12 6.5% 15.1%',
        'muted-foreground': '24 5.4% 63.9%',
        accent: '12 6.5% 15.1%',
        'accent-foreground': '60 9.1% 97.8%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '60 9.1% 97.8%',
        border: '12 6.5% 15.1%',
        input: '12 6.5% 15.1%',
        ring: '35.5 91.7% 32.9%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
  {
    name: 'violet',
    label: 'Violet',
    activeColor: {
      light: '262.1 83.3% 57.8%',
      dark: '263.4 70% 50.4%',
    },
    cssVars: {
      light: {
        background: '0 0% 100%',
        foreground: '224 71.4% 4.1%',
        card: '0 0% 100%',
        'card-foreground': '224 71.4% 4.1%',
        popover: '0 0% 100%',
        'popover-foreground': '224 71.4% 4.1%',
        primary: '262.1 83.3% 57.8%',
        'primary-foreground': '210 20% 98%',
        secondary: '220 14.3% 95.9%',
        'secondary-foreground': '220.9 39.3% 11%',
        muted: '220 14.3% 95.9%',
        'muted-foreground': '220 8.9% 46.1%',
        accent: '220 14.3% 95.9%',
        'accent-foreground': '220.9 39.3% 11%',
        destructive: '0 84.2% 60.2%',
        'destructive-foreground': '210 20% 98%',
        border: '220 13% 91%',
        input: '220 13% 91%',
        ring: '262.1 83.3% 57.8%',
        'chart-1': '12 76% 61%',
        'chart-2': '173 58% 39%',
        'chart-3': '197 37% 24%',
        'chart-4': '43 74% 66%',
        'chart-5': '27 87% 67%',
      },
      dark: {
        background: '224 71.4% 4.1%',
        foreground: '210 20% 98%',
        card: '224 71.4% 4.1%',
        'card-foreground': '210 20% 98%',
        popover: '224 71.4% 4.1%',
        'popover-foreground': '210 20% 98%',
        primary: '263.4 70% 50.4%',
        'primary-foreground': '210 20% 98%',
        secondary: '215 27.9% 16.9%',
        'secondary-foreground': '210 20% 98%',
        muted: '215 27.9% 16.9%',
        'muted-foreground': '217.9 10.6% 64.9%',
        accent: '215 27.9% 16.9%',
        'accent-foreground': '210 20% 98%',
        destructive: '0 62.8% 30.6%',
        'destructive-foreground': '210 20% 98%',
        border: '215 27.9% 16.9%',
        input: '215 27.9% 16.9%',
        ring: '263.4 70% 50.4%',
        'chart-1': '220 70% 50%',
        'chart-2': '160 60% 45%',
        'chart-3': '30 80% 55%',
        'chart-4': '280 65% 60%',
        'chart-5': '340 75% 55%',
      },
    },
  },
] as const

export const baseColorMap = new Map(baseColors.map((color) => [color.name, color]))

export type BaseColor = (typeof baseColors)[number]
export type ColorName = BaseColor['name']

export const getThemeColor = (name: ColorName) => {
  return baseColorMap.get(name)
}

/**
 * Source
 * https://github.com/shadcn-ui/ui/blob/main/apps/www/registry/registry-colors.ts
 */
export const colors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: {
    hex: '#000000',
    rgb: 'rgb(0,0,0)',
    hsl: 'hsl(0,0%,0%)',
  },
  white: {
    hex: '#ffffff',
    rgb: 'rgb(255,255,255)',
    hsl: 'hsl(0,0%,100%)',
  },
  slate: [
    {
      scale: 50,
      hex: '#f8fafc',
      rgb: 'rgb(248,250,252)',
      hsl: 'hsl(210,40%,98%)',
    },
    {
      scale: 100,
      hex: '#f1f5f9',
      rgb: 'rgb(241,245,249)',
      hsl: 'hsl(210,40%,96.1%)',
    },
    {
      scale: 200,
      hex: '#e2e8f0',
      rgb: 'rgb(226,232,240)',
      hsl: 'hsl(214.3,31.8%,91.4%)',
    },
    {
      scale: 300,
      hex: '#cbd5e1',
      rgb: 'rgb(203,213,225)',
      hsl: 'hsl(212.7,26.8%,83.9%)',
    },
    {
      scale: 400,
      hex: '#94a3b8',
      rgb: 'rgb(148,163,184)',
      hsl: 'hsl(215,20.2%,65.1%)',
    },
    {
      scale: 500,
      hex: '#64748b',
      rgb: 'rgb(100,116,139)',
      hsl: 'hsl(215.4,16.3%,46.9%)',
    },
    {
      scale: 600,
      hex: '#475569',
      rgb: 'rgb(71,85,105)',
      hsl: 'hsl(215.3,19.3%,34.5%)',
    },
    {
      scale: 700,
      hex: '#334155',
      rgb: 'rgb(51,65,85)',
      hsl: 'hsl(215.3,25%,26.7%)',
    },
    {
      scale: 800,
      hex: '#1e293b',
      rgb: 'rgb(30,41,59)',
      hsl: 'hsl(217.2,32.6%,17.5%)',
    },
    {
      scale: 900,
      hex: '#0f172a',
      rgb: 'rgb(15,23,42)',
      hsl: 'hsl(222.2,47.4%,11.2%)',
    },
    {
      scale: 950,
      hex: '#020617',
      rgb: 'rgb(2,6,23)',
      hsl: 'hsl(222.2,84%,4.9%)',
    },
  ],
  gray: [
    {
      scale: 50,
      hex: '#f9fafb',
      rgb: 'rgb(249,250,251)',
      hsl: 'hsl(210,20%,98%)',
    },
    {
      scale: 100,
      hex: '#f3f4f6',
      rgb: 'rgb(243,244,246)',
      hsl: 'hsl(220,14.3%,95.9%)',
    },
    {
      scale: 200,
      hex: '#e5e7eb',
      rgb: 'rgb(229,231,235)',
      hsl: 'hsl(220,13%,91%)',
    },
    {
      scale: 300,
      hex: '#d1d5db',
      rgb: 'rgb(209,213,219)',
      hsl: 'hsl(216,12.2%,83.9%)',
    },
    {
      scale: 400,
      hex: '#9ca3af',
      rgb: 'rgb(156,163,175)',
      hsl: 'hsl(217.9,10.6%,64.9%)',
    },
    {
      scale: 500,
      hex: '#6b7280',
      rgb: 'rgb(107,114,128)',
      hsl: 'hsl(220,8.9%,46.1%)',
    },
    {
      scale: 600,
      hex: '#4b5563',
      rgb: 'rgb(75,85,99)',
      hsl: 'hsl(215,13.8%,34.1%)',
    },
    {
      scale: 700,
      hex: '#374151',
      rgb: 'rgb(55,65,81)',
      hsl: 'hsl(216.9,19.1%,26.7%)',
    },
    {
      scale: 800,
      hex: '#1f2937',
      rgb: 'rgb(31,41,55)',
      hsl: 'hsl(215,27.9%,16.9%)',
    },
    {
      scale: 900,
      hex: '#111827',
      rgb: 'rgb(17,24,39)',
      hsl: 'hsl(220.9,39.3%,11%)',
    },
    {
      scale: 950,
      hex: '#030712',
      rgb: 'rgb(3,7,18)',
      hsl: 'hsl(224,71.4%,4.1%)',
    },
  ],
  zinc: [
    {
      scale: 50,
      hex: '#fafafa',
      rgb: 'rgb(250,250,250)',
      hsl: 'hsl(0,0%,98%)',
    },
    {
      scale: 100,
      hex: '#f4f4f5',
      rgb: 'rgb(244,244,245)',
      hsl: 'hsl(240,4.8%,95.9%)',
    },
    {
      scale: 200,
      hex: '#e4e4e7',
      rgb: 'rgb(228,228,231)',
      hsl: 'hsl(240,5.9%,90%)',
    },
    {
      scale: 300,
      hex: '#d4d4d8',
      rgb: 'rgb(212,212,216)',
      hsl: 'hsl(240,4.9%,83.9%)',
    },
    {
      scale: 400,
      hex: '#a1a1aa',
      rgb: 'rgb(161,161,170)',
      hsl: 'hsl(240,5%,64.9%)',
    },
    {
      scale: 500,
      hex: '#71717a',
      rgb: 'rgb(113,113,122)',
      hsl: 'hsl(240,3.8%,46.1%)',
    },
    {
      scale: 600,
      hex: '#52525b',
      rgb: 'rgb(82,82,91)',
      hsl: 'hsl(240,5.2%,33.9%)',
    },
    {
      scale: 700,
      hex: '#3f3f46',
      rgb: 'rgb(63,63,70)',
      hsl: 'hsl(240,5.3%,26.1%)',
    },
    {
      scale: 800,
      hex: '#27272a',
      rgb: 'rgb(39,39,42)',
      hsl: 'hsl(240,3.7%,15.9%)',
    },
    {
      scale: 900,
      hex: '#18181b',
      rgb: 'rgb(24,24,27)',
      hsl: 'hsl(240,5.9%,10%)',
    },
    {
      scale: 950,
      hex: '#09090b',
      rgb: 'rgb(9,9,11)',
      hsl: 'hsl(240,10%,3.9%)',
    },
  ],
  neutral: [
    {
      scale: 50,
      hex: '#fafafa',
      rgb: 'rgb(250,250,250)',
      hsl: 'hsl(0,0%,98%)',
    },
    {
      scale: 100,
      hex: '#f5f5f5',
      rgb: 'rgb(245,245,245)',
      hsl: 'hsl(0,0%,96.1%)',
    },
    {
      scale: 200,
      hex: '#e5e5e5',
      rgb: 'rgb(229,229,229)',
      hsl: 'hsl(0,0%,89.8%)',
    },
    {
      scale: 300,
      hex: '#d4d4d4',
      rgb: 'rgb(212,212,212)',
      hsl: 'hsl(0,0%,83.1%)',
    },
    {
      scale: 400,
      hex: '#a3a3a3',
      rgb: 'rgb(163,163,163)',
      hsl: 'hsl(0,0%,63.9%)',
    },
    {
      scale: 500,
      hex: '#737373',
      rgb: 'rgb(115,115,115)',
      hsl: 'hsl(0,0%,45.1%)',
    },
    {
      scale: 600,
      hex: '#525252',
      rgb: 'rgb(82,82,82)',
      hsl: 'hsl(0,0%,32.2%)',
    },
    {
      scale: 700,
      hex: '#404040',
      rgb: 'rgb(64,64,64)',
      hsl: 'hsl(0,0%,25.1%)',
    },
    {
      scale: 800,
      hex: '#262626',
      rgb: 'rgb(38,38,38)',
      hsl: 'hsl(0,0%,14.9%)',
    },
    {
      scale: 900,
      hex: '#171717',
      rgb: 'rgb(23,23,23)',
      hsl: 'hsl(0,0%,9%)',
    },
    {
      scale: 950,
      hex: '#0a0a0a',
      rgb: 'rgb(10,10,10)',
      hsl: 'hsl(0,0%,3.9%)',
    },
  ],
  stone: [
    {
      scale: 50,
      hex: '#fafaf9',
      rgb: 'rgb(250,250,249)',
      hsl: 'hsl(60,9.1%,97.8%)',
    },
    {
      scale: 100,
      hex: '#f5f5f4',
      rgb: 'rgb(245,245,244)',
      hsl: 'hsl(60,4.8%,95.9%)',
    },
    {
      scale: 200,
      hex: '#e7e5e4',
      rgb: 'rgb(231,229,228)',
      hsl: 'hsl(20,5.9%,90%)',
    },
    {
      scale: 300,
      hex: '#d6d3d1',
      rgb: 'rgb(214,211,209)',
      hsl: 'hsl(24,5.7%,82.9%)',
    },
    {
      scale: 400,
      hex: '#a8a29e',
      rgb: 'rgb(168,162,158)',
      hsl: 'hsl(24,5.4%,63.9%)',
    },
    {
      scale: 500,
      hex: '#78716c',
      rgb: 'rgb(120,113,108)',
      hsl: 'hsl(25,5.3%,44.7%)',
    },
    {
      scale: 600,
      hex: '#57534e',
      rgb: 'rgb(87,83,78)',
      hsl: 'hsl(33.3,5.5%,32.4%)',
    },
    {
      scale: 700,
      hex: '#44403c',
      rgb: 'rgb(68,64,60)',
      hsl: 'hsl(30,6.3%,25.1%)',
    },
    {
      scale: 800,
      hex: '#292524',
      rgb: 'rgb(41,37,36)',
      hsl: 'hsl(12,6.5%,15.1%)',
    },
    {
      scale: 900,
      hex: '#1c1917',
      rgb: 'rgb(28,25,23)',
      hsl: 'hsl(24,9.8%,10%)',
    },
    {
      scale: 950,
      hex: '#0c0a09',
      rgb: 'rgb(12,10,9)',
      hsl: 'hsl(20,14.3%,4.1%)',
    },
  ],
  red: [
    {
      scale: 50,
      hex: '#fef2f2',
      rgb: 'rgb(254,242,242)',
      hsl: 'hsl(0,85.7%,97.3%)',
    },
    {
      scale: 100,
      hex: '#fee2e2',
      rgb: 'rgb(254,226,226)',
      hsl: 'hsl(0,93.3%,94.1%)',
    },
    {
      scale: 200,
      hex: '#fecaca',
      rgb: 'rgb(254,202,202)',
      hsl: 'hsl(0,96.3%,89.4%)',
    },
    {
      scale: 300,
      hex: '#fca5a5',
      rgb: 'rgb(252,165,165)',
      hsl: 'hsl(0,93.5%,81.8%)',
    },
    {
      scale: 400,
      hex: '#f87171',
      rgb: 'rgb(248,113,113)',
      hsl: 'hsl(0,90.6%,70.8%)',
    },
    {
      scale: 500,
      hex: '#ef4444',
      rgb: 'rgb(239,68,68)',
      hsl: 'hsl(0,84.2%,60.2%)',
    },
    {
      scale: 600,
      hex: '#dc2626',
      rgb: 'rgb(220,38,38)',
      hsl: 'hsl(0,72.2%,50.6%)',
    },
    {
      scale: 700,
      hex: '#b91c1c',
      rgb: 'rgb(185,28,28)',
      hsl: 'hsl(0,73.7%,41.8%)',
    },
    {
      scale: 800,
      hex: '#991b1b',
      rgb: 'rgb(153,27,27)',
      hsl: 'hsl(0,70%,35.3%)',
    },
    {
      scale: 900,
      hex: '#7f1d1d',
      rgb: 'rgb(127,29,29)',
      hsl: 'hsl(0,62.8%,30.6%)',
    },
    {
      scale: 950,
      hex: '#450a0a',
      rgb: 'rgb(69,10,10)',
      hsl: 'hsl(0,74.7%,15.5%)',
    },
  ],
  orange: [
    {
      scale: 50,
      hex: '#fff7ed',
      rgb: 'rgb(255,247,237)',
      hsl: 'hsl(33.3,100%,96.5%)',
    },
    {
      scale: 100,
      hex: '#ffedd5',
      rgb: 'rgb(255,237,213)',
      hsl: 'hsl(34.3,100%,91.8%)',
    },
    {
      scale: 200,
      hex: '#fed7aa',
      rgb: 'rgb(254,215,170)',
      hsl: 'hsl(32.1,97.7%,83.1%)',
    },
    {
      scale: 300,
      hex: '#fdba74',
      rgb: 'rgb(253,186,116)',
      hsl: 'hsl(30.7,97.2%,72.4%)',
    },
    {
      scale: 400,
      hex: '#fb923c',
      rgb: 'rgb(251,146,60)',
      hsl: 'hsl(27,96%,61%)',
    },
    {
      scale: 500,
      hex: '#f97316',
      rgb: 'rgb(249,115,22)',
      hsl: 'hsl(24.6,95%,53.1%)',
    },
    {
      scale: 600,
      hex: '#ea580c',
      rgb: 'rgb(234,88,12)',
      hsl: 'hsl(20.5,90.2%,48.2%)',
    },
    {
      scale: 700,
      hex: '#c2410c',
      rgb: 'rgb(194,65,12)',
      hsl: 'hsl(17.5,88.3%,40.4%)',
    },
    {
      scale: 800,
      hex: '#9a3412',
      rgb: 'rgb(154,52,18)',
      hsl: 'hsl(15,79.1%,33.7%)',
    },
    {
      scale: 900,
      hex: '#7c2d12',
      rgb: 'rgb(124,45,18)',
      hsl: 'hsl(15.3,74.6%,27.8%)',
    },
    {
      scale: 950,
      hex: '#431407',
      rgb: 'rgb(67,20,7)',
      hsl: 'hsl(13,81.1%,14.5%)',
    },
  ],
  amber: [
    {
      scale: 50,
      hex: '#fffbeb',
      rgb: 'rgb(255,251,235)',
      hsl: 'hsl(48,100%,96.1%)',
    },
    {
      scale: 100,
      hex: '#fef3c7',
      rgb: 'rgb(254,243,199)',
      hsl: 'hsl(48,96.5%,88.8%)',
    },
    {
      scale: 200,
      hex: '#fde68a',
      rgb: 'rgb(253,230,138)',
      hsl: 'hsl(48,96.6%,76.7%)',
    },
    {
      scale: 300,
      hex: '#fcd34d',
      rgb: 'rgb(252,211,77)',
      hsl: 'hsl(45.9,96.7%,64.5%)',
    },
    {
      scale: 400,
      hex: '#fbbf24',
      rgb: 'rgb(251,191,36)',
      hsl: 'hsl(43.3,96.4%,56.3%)',
    },
    {
      scale: 500,
      hex: '#f59e0b',
      rgb: 'rgb(245,158,11)',
      hsl: 'hsl(37.7,92.1%,50.2%)',
    },
    {
      scale: 600,
      hex: '#d97706',
      rgb: 'rgb(217,119,6)',
      hsl: 'hsl(32.1,94.6%,43.7%)',
    },
    {
      scale: 700,
      hex: '#b45309',
      rgb: 'rgb(180,83,9)',
      hsl: 'hsl(26,90.5%,37.1%)',
    },
    {
      scale: 800,
      hex: '#92400e',
      rgb: 'rgb(146,64,14)',
      hsl: 'hsl(22.7,82.5%,31.4%)',
    },
    {
      scale: 900,
      hex: '#78350f',
      rgb: 'rgb(120,53,15)',
      hsl: 'hsl(21.7,77.8%,26.5%)',
    },
    {
      scale: 950,
      hex: '#451a03',
      rgb: 'rgb(69,26,3)',
      hsl: 'hsl(20.9,91.7%,14.1%)',
    },
  ],
  yellow: [
    {
      scale: 50,
      hex: '#fefce8',
      rgb: 'rgb(254,252,232)',
      hsl: 'hsl(54.5,91.7%,95.3%)',
    },
    {
      scale: 100,
      hex: '#fef9c3',
      rgb: 'rgb(254,249,195)',
      hsl: 'hsl(54.9,96.7%,88%)',
    },
    {
      scale: 200,
      hex: '#fef08a',
      rgb: 'rgb(254,240,138)',
      hsl: 'hsl(52.8,98.3%,76.9%)',
    },
    {
      scale: 300,
      hex: '#fde047',
      rgb: 'rgb(253,224,71)',
      hsl: 'hsl(50.4,97.8%,63.5%)',
    },
    {
      scale: 400,
      hex: '#facc15',
      rgb: 'rgb(250,204,21)',
      hsl: 'hsl(47.9,95.8%,53.1%)',
    },
    {
      scale: 500,
      hex: '#eab308',
      rgb: 'rgb(234,179,8)',
      hsl: 'hsl(45.4,93.4%,47.5%)',
    },
    {
      scale: 600,
      hex: '#ca8a04',
      rgb: 'rgb(202,138,4)',
      hsl: 'hsl(40.6,96.1%,40.4%)',
    },
    {
      scale: 700,
      hex: '#a16207',
      rgb: 'rgb(161,98,7)',
      hsl: 'hsl(35.5,91.7%,32.9%)',
    },
    {
      scale: 800,
      hex: '#854d0e',
      rgb: 'rgb(133,77,14)',
      hsl: 'hsl(31.8,81%,28.8%)',
    },
    {
      scale: 900,
      hex: '#713f12',
      rgb: 'rgb(113,63,18)',
      hsl: 'hsl(28.4,72.5%,25.7%)',
    },
    {
      scale: 950,
      hex: '#422006',
      rgb: 'rgb(66,32,6)',
      hsl: 'hsl(26,83.3%,14.1%)',
    },
  ],
  lime: [
    {
      scale: 50,
      hex: '#f7fee7',
      rgb: 'rgb(247,254,231)',
      hsl: 'hsl(78.3,92%,95.1%)',
    },
    {
      scale: 100,
      hex: '#ecfccb',
      rgb: 'rgb(236,252,203)',
      hsl: 'hsl(79.6,89.1%,89.2%)',
    },
    {
      scale: 200,
      hex: '#d9f99d',
      rgb: 'rgb(217,249,157)',
      hsl: 'hsl(80.9,88.5%,79.6%)',
    },
    {
      scale: 300,
      hex: '#bef264',
      rgb: 'rgb(190,242,100)',
      hsl: 'hsl(82,84.5%,67.1%)',
    },
    {
      scale: 400,
      hex: '#a3e635',
      rgb: 'rgb(163,230,53)',
      hsl: 'hsl(82.7,78%,55.5%)',
    },
    {
      scale: 500,
      hex: '#84cc16',
      rgb: 'rgb(132,204,22)',
      hsl: 'hsl(83.7,80.5%,44.3%)',
    },
    {
      scale: 600,
      hex: '#65a30d',
      rgb: 'rgb(101,163,13)',
      hsl: 'hsl(84.8,85.2%,34.5%)',
    },
    {
      scale: 700,
      hex: '#4d7c0f',
      rgb: 'rgb(77,124,15)',
      hsl: 'hsl(85.9,78.4%,27.3%)',
    },
    {
      scale: 800,
      hex: '#3f6212',
      rgb: 'rgb(63,98,18)',
      hsl: 'hsl(86.3,69%,22.7%)',
    },
    {
      scale: 900,
      hex: '#365314',
      rgb: 'rgb(54,83,20)',
      hsl: 'hsl(87.6,61.2%,20.2%)',
    },
    {
      scale: 950,
      hex: '#1a2e05',
      rgb: 'rgb(26,46,5)',
      hsl: 'hsl(89.3,80.4%,10%)',
    },
  ],
  green: [
    {
      scale: 50,
      hex: '#f0fdf4',
      rgb: 'rgb(240,253,244)',
      hsl: 'hsl(138.5,76.5%,96.7%)',
    },
    {
      scale: 100,
      hex: '#dcfce7',
      rgb: 'rgb(220,252,231)',
      hsl: 'hsl(140.6,84.2%,92.5%)',
    },
    {
      scale: 200,
      hex: '#bbf7d0',
      rgb: 'rgb(187,247,208)',
      hsl: 'hsl(141,78.9%,85.1%)',
    },
    {
      scale: 300,
      hex: '#86efac',
      rgb: 'rgb(134,239,172)',
      hsl: 'hsl(141.7,76.6%,73.1%)',
    },
    {
      scale: 400,
      hex: '#4ade80',
      rgb: 'rgb(74,222,128)',
      hsl: 'hsl(141.9,69.2%,58%)',
    },
    {
      scale: 500,
      hex: '#22c55e',
      rgb: 'rgb(34,197,94)',
      hsl: 'hsl(142.1,70.6%,45.3%)',
    },
    {
      scale: 600,
      hex: '#16a34a',
      rgb: 'rgb(22,163,74)',
      hsl: 'hsl(142.1,76.2%,36.3%)',
    },
    {
      scale: 700,
      hex: '#15803d',
      rgb: 'rgb(21,128,61)',
      hsl: 'hsl(142.4,71.8%,29.2%)',
    },
    {
      scale: 800,
      hex: '#166534',
      rgb: 'rgb(22,101,52)',
      hsl: 'hsl(142.8,64.2%,24.1%)',
    },
    {
      scale: 900,
      hex: '#14532d',
      rgb: 'rgb(20,83,45)',
      hsl: 'hsl(143.8,61.2%,20.2%)',
    },
    {
      scale: 950,
      hex: '#052e16',
      rgb: 'rgb(5,46,22)',
      hsl: 'hsl(144.9,80.4%,10%)',
    },
  ],
  emerald: [
    {
      scale: 50,
      hex: '#ecfdf5',
      rgb: 'rgb(236,253,245)',
      hsl: 'hsl(151.8,81%,95.9%)',
    },
    {
      scale: 100,
      hex: '#d1fae5',
      rgb: 'rgb(209,250,229)',
      hsl: 'hsl(149.3,80.4%,90%)',
    },
    {
      scale: 200,
      hex: '#a7f3d0',
      rgb: 'rgb(167,243,208)',
      hsl: 'hsl(152.4,76%,80.4%)',
    },
    {
      scale: 300,
      hex: '#6ee7b7',
      rgb: 'rgb(110,231,183)',
      hsl: 'hsl(156.2,71.6%,66.9%)',
    },
    {
      scale: 400,
      hex: '#34d399',
      rgb: 'rgb(52,211,153)',
      hsl: 'hsl(158.1,64.4%,51.6%)',
    },
    {
      scale: 500,
      hex: '#10b981',
      rgb: 'rgb(16,185,129)',
      hsl: 'hsl(160.1,84.1%,39.4%)',
    },
    {
      scale: 600,
      hex: '#059669',
      rgb: 'rgb(5,150,105)',
      hsl: 'hsl(161.4,93.5%,30.4%)',
    },
    {
      scale: 700,
      hex: '#047857',
      rgb: 'rgb(4,120,87)',
      hsl: 'hsl(162.9,93.5%,24.3%)',
    },
    {
      scale: 800,
      hex: '#065f46',
      rgb: 'rgb(6,95,70)',
      hsl: 'hsl(163.1,88.1%,19.8%)',
    },
    {
      scale: 900,
      hex: '#064e3b',
      rgb: 'rgb(6,78,59)',
      hsl: 'hsl(164.2,85.7%,16.5%)',
    },
    {
      scale: 950,
      hex: '#022c22',
      rgb: 'rgb(2,44,34)',
      hsl: 'hsl(165.7,91.3%,9%)',
    },
  ],
  teal: [
    {
      scale: 50,
      hex: '#f0fdfa',
      rgb: 'rgb(240,253,250)',
      hsl: 'hsl(166.2,76.5%,96.7%)',
    },
    {
      scale: 100,
      hex: '#ccfbf1',
      rgb: 'rgb(204,251,241)',
      hsl: 'hsl(167.2,85.5%,89.2%)',
    },
    {
      scale: 200,
      hex: '#99f6e4',
      rgb: 'rgb(153,246,228)',
      hsl: 'hsl(168.4,83.8%,78.2%)',
    },
    {
      scale: 300,
      hex: '#5eead4',
      rgb: 'rgb(94,234,212)',
      hsl: 'hsl(170.6,76.9%,64.3%)',
    },
    {
      scale: 400,
      hex: '#2dd4bf',
      rgb: 'rgb(45,212,191)',
      hsl: 'hsl(172.5,66%,50.4%)',
    },
    {
      scale: 500,
      hex: '#14b8a6',
      rgb: 'rgb(20,184,166)',
      hsl: 'hsl(173.4,80.4%,40%)',
    },
    {
      scale: 600,
      hex: '#0d9488',
      rgb: 'rgb(13,148,136)',
      hsl: 'hsl(174.7,83.9%,31.6%)',
    },
    {
      scale: 700,
      hex: '#0f766e',
      rgb: 'rgb(15,118,110)',
      hsl: 'hsl(175.3,77.4%,26.1%)',
    },
    {
      scale: 800,
      hex: '#115e59',
      rgb: 'rgb(17,94,89)',
      hsl: 'hsl(176.1,69.4%,21.8%)',
    },
    {
      scale: 900,
      hex: '#134e4a',
      rgb: 'rgb(19,78,74)',
      hsl: 'hsl(175.9,60.8%,19%)',
    },
    {
      scale: 950,
      hex: '#042f2e',
      rgb: 'rgb(4,47,46)',
      hsl: 'hsl(178.6,84.3%,10%)',
    },
  ],
  cyan: [
    {
      scale: 50,
      hex: '#ecfeff',
      rgb: 'rgb(236,254,255)',
      hsl: 'hsl(183.2,100%,96.3%)',
    },
    {
      scale: 100,
      hex: '#cffafe',
      rgb: 'rgb(207,250,254)',
      hsl: 'hsl(185.1,95.9%,90.4%)',
    },
    {
      scale: 200,
      hex: '#a5f3fc',
      rgb: 'rgb(165,243,252)',
      hsl: 'hsl(186.2,93.5%,81.8%)',
    },
    {
      scale: 300,
      hex: '#67e8f9',
      rgb: 'rgb(103,232,249)',
      hsl: 'hsl(187,92.4%,69%)',
    },
    {
      scale: 400,
      hex: '#22d3ee',
      rgb: 'rgb(34,211,238)',
      hsl: 'hsl(187.9,85.7%,53.3%)',
    },
    {
      scale: 500,
      hex: '#06b6d4',
      rgb: 'rgb(6,182,212)',
      hsl: 'hsl(188.7,94.5%,42.7%)',
    },
    {
      scale: 600,
      hex: '#0891b2',
      rgb: 'rgb(8,145,178)',
      hsl: 'hsl(191.6,91.4%,36.5%)',
    },
    {
      scale: 700,
      hex: '#0e7490',
      rgb: 'rgb(14,116,144)',
      hsl: 'hsl(192.9,82.3%,31%)',
    },
    {
      scale: 800,
      hex: '#155e75',
      rgb: 'rgb(21,94,117)',
      hsl: 'hsl(194.4,69.6%,27.1%)',
    },
    {
      scale: 900,
      hex: '#164e63',
      rgb: 'rgb(22,78,99)',
      hsl: 'hsl(196.4,63.6%,23.7%)',
    },
    {
      scale: 950,
      hex: '#083344',
      rgb: 'rgb(8,51,68)',
      hsl: 'hsl(197,78.9%,14.9%)',
    },
  ],
  sky: [
    {
      scale: 50,
      hex: '#f0f9ff',
      rgb: 'rgb(240,249,255)',
      hsl: 'hsl(204,100%,97.1%)',
    },
    {
      scale: 100,
      hex: '#e0f2fe',
      rgb: 'rgb(224,242,254)',
      hsl: 'hsl(204,93.8%,93.7%)',
    },
    {
      scale: 200,
      hex: '#bae6fd',
      rgb: 'rgb(186,230,253)',
      hsl: 'hsl(200.6,94.4%,86.1%)',
    },
    {
      scale: 300,
      hex: '#7dd3fc',
      rgb: 'rgb(125,211,252)',
      hsl: 'hsl(199.4,95.5%,73.9%)',
    },
    {
      scale: 400,
      hex: '#38bdf8',
      rgb: 'rgb(56,189,248)',
      hsl: 'hsl(198.4,93.2%,59.6%)',
    },
    {
      scale: 500,
      hex: '#0ea5e9',
      rgb: 'rgb(14,165,233)',
      hsl: 'hsl(198.6,88.7%,48.4%)',
    },
    {
      scale: 600,
      hex: '#0284c7',
      rgb: 'rgb(2,132,199)',
      hsl: 'hsl(200.4,98%,39.4%)',
    },
    {
      scale: 700,
      hex: '#0369a1',
      rgb: 'rgb(3,105,161)',
      hsl: 'hsl(201.3,96.3%,32.2%)',
    },
    {
      scale: 800,
      hex: '#075985',
      rgb: 'rgb(7,89,133)',
      hsl: 'hsl(201,90%,27.5%)',
    },
    {
      scale: 900,
      hex: '#0c4a6e',
      rgb: 'rgb(12,74,110)',
      hsl: 'hsl(202,80.3%,23.9%)',
    },
    {
      scale: 950,
      hex: '#082f49',
      rgb: 'rgb(8,47,73)',
      hsl: 'hsl(204,80.2%,15.9%)',
    },
  ],
  blue: [
    {
      scale: 50,
      hex: '#eff6ff',
      rgb: 'rgb(239,246,255)',
      hsl: 'hsl(213.8,100%,96.9%)',
    },
    {
      scale: 100,
      hex: '#dbeafe',
      rgb: 'rgb(219,234,254)',
      hsl: 'hsl(214.3,94.6%,92.7%)',
    },
    {
      scale: 200,
      hex: '#bfdbfe',
      rgb: 'rgb(191,219,254)',
      hsl: 'hsl(213.3,96.9%,87.3%)',
    },
    {
      scale: 300,
      hex: '#93c5fd',
      rgb: 'rgb(147,197,253)',
      hsl: 'hsl(211.7,96.4%,78.4%)',
    },
    {
      scale: 400,
      hex: '#60a5fa',
      rgb: 'rgb(96,165,250)',
      hsl: 'hsl(213.1,93.9%,67.8%)',
    },
    {
      scale: 500,
      hex: '#3b82f6',
      rgb: 'rgb(59,130,246)',
      hsl: 'hsl(217.2,91.2%,59.8%)',
    },
    {
      scale: 600,
      hex: '#2563eb',
      rgb: 'rgb(37,99,235)',
      hsl: 'hsl(221.2,83.2%,53.3%)',
    },
    {
      scale: 700,
      hex: '#1d4ed8',
      rgb: 'rgb(29,78,216)',
      hsl: 'hsl(224.3,76.3%,48%)',
    },
    {
      scale: 800,
      hex: '#1e40af',
      rgb: 'rgb(30,64,175)',
      hsl: 'hsl(225.9,70.7%,40.2%)',
    },
    {
      scale: 900,
      hex: '#1e3a8a',
      rgb: 'rgb(30,58,138)',
      hsl: 'hsl(224.4,64.3%,32.9%)',
    },
    {
      scale: 950,
      hex: '#172554',
      rgb: 'rgb(23,37,84)',
      hsl: 'hsl(226.2,57%,21%)',
    },
  ],
  indigo: [
    {
      scale: 50,
      hex: '#eef2ff',
      rgb: 'rgb(238,242,255)',
      hsl: 'hsl(225.9,100%,96.7%)',
    },
    {
      scale: 100,
      hex: '#e0e7ff',
      rgb: 'rgb(224,231,255)',
      hsl: 'hsl(226.5,100%,93.9%)',
    },
    {
      scale: 200,
      hex: '#c7d2fe',
      rgb: 'rgb(199,210,254)',
      hsl: 'hsl(228,96.5%,88.8%)',
    },
    {
      scale: 300,
      hex: '#a5b4fc',
      rgb: 'rgb(165,180,252)',
      hsl: 'hsl(229.7,93.5%,81.8%)',
    },
    {
      scale: 400,
      hex: '#818cf8',
      rgb: 'rgb(129,140,248)',
      hsl: 'hsl(234.5,89.5%,73.9%)',
    },
    {
      scale: 500,
      hex: '#6366f1',
      rgb: 'rgb(99,102,241)',
      hsl: 'hsl(238.7,83.5%,66.7%)',
    },
    {
      scale: 600,
      hex: '#4f46e5',
      rgb: 'rgb(79,70,229)',
      hsl: 'hsl(243.4,75.4%,58.6%)',
    },
    {
      scale: 700,
      hex: '#4338ca',
      rgb: 'rgb(67,56,202)',
      hsl: 'hsl(244.5,57.9%,50.6%)',
    },
    {
      scale: 800,
      hex: '#3730a3',
      rgb: 'rgb(55,48,163)',
      hsl: 'hsl(243.7,54.5%,41.4%)',
    },
    {
      scale: 900,
      hex: '#312e81',
      rgb: 'rgb(49,46,129)',
      hsl: 'hsl(242.2,47.4%,34.3%)',
    },
    {
      scale: 950,
      hex: '#1e1b4b',
      rgb: 'rgb(30,27,75)',
      hsl: 'hsl(243.8,47.1%,20%)',
    },
  ],
  violet: [
    {
      scale: 50,
      hex: '#f5f3ff',
      rgb: 'rgb(245,243,255)',
      hsl: 'hsl(250,100%,97.6%)',
    },
    {
      scale: 100,
      hex: '#ede9fe',
      rgb: 'rgb(237,233,254)',
      hsl: 'hsl(251.4,91.3%,95.5%)',
    },
    {
      scale: 200,
      hex: '#ddd6fe',
      rgb: 'rgb(221,214,254)',
      hsl: 'hsl(250.5,95.2%,91.8%)',
    },
    {
      scale: 300,
      hex: '#c4b5fd',
      rgb: 'rgb(196,181,253)',
      hsl: 'hsl(252.5,94.7%,85.1%)',
    },
    {
      scale: 400,
      hex: '#a78bfa',
      rgb: 'rgb(167,139,250)',
      hsl: 'hsl(255.1,91.7%,76.3%)',
    },
    {
      scale: 500,
      hex: '#8b5cf6',
      rgb: 'rgb(139,92,246)',
      hsl: 'hsl(258.3,89.5%,66.3%)',
    },
    {
      scale: 600,
      hex: '#7c3aed',
      rgb: 'rgb(124,58,237)',
      hsl: 'hsl(262.1,83.3%,57.8%)',
    },
    {
      scale: 700,
      hex: '#6d28d9',
      rgb: 'rgb(109,40,217)',
      hsl: 'hsl(263.4,70%,50.4%)',
    },
    {
      scale: 800,
      hex: '#5b21b6',
      rgb: 'rgb(91,33,182)',
      hsl: 'hsl(263.4,69.3%,42.2%)',
    },
    {
      scale: 900,
      hex: '#4c1d95',
      rgb: 'rgb(76,29,149)',
      hsl: 'hsl(263.5,67.4%,34.9%)',
    },
    {
      scale: 950,
      hex: '#1e1b4b',
      rgb: 'rgb(46,16,101)',
      hsl: 'hsl(261.2,72.6%,22.9%)',
    },
  ],
  purple: [
    {
      scale: 50,
      hex: '#faf5ff',
      rgb: 'rgb(250,245,255)',
      hsl: 'hsl(270,100%,98%)',
    },
    {
      scale: 100,
      hex: '#f3e8ff',
      rgb: 'rgb(243,232,255)',
      hsl: 'hsl(268.7,100%,95.5%)',
    },
    {
      scale: 200,
      hex: '#e9d5ff',
      rgb: 'rgb(233,213,255)',
      hsl: 'hsl(268.6,100%,91.8%)',
    },
    {
      scale: 300,
      hex: '#d8b4fe',
      rgb: 'rgb(216,180,254)',
      hsl: 'hsl(269.2,97.4%,85.1%)',
    },
    {
      scale: 400,
      hex: '#c084fc',
      rgb: 'rgb(192,132,252)',
      hsl: 'hsl(270,95.2%,75.3%)',
    },
    {
      scale: 500,
      hex: '#a855f7',
      rgb: 'rgb(168,85,247)',
      hsl: 'hsl(270.7,91%,65.1%)',
    },
    {
      scale: 600,
      hex: '#9333ea',
      rgb: 'rgb(147,51,234)',
      hsl: 'hsl(271.5,81.3%,55.9%)',
    },
    {
      scale: 700,
      hex: '#7e22ce',
      rgb: 'rgb(126,34,206)',
      hsl: 'hsl(272.1,71.7%,47.1%)',
    },
    {
      scale: 800,
      hex: '#6b21a8',
      rgb: 'rgb(107,33,168)',
      hsl: 'hsl(272.9,67.2%,39.4%)',
    },
    {
      scale: 900,
      hex: '#581c87',
      rgb: 'rgb(88,28,135)',
      hsl: 'hsl(273.6,65.6%,32%)',
    },
    {
      scale: 950,
      hex: '#3b0764',
      rgb: 'rgb(59,7,100)',
      hsl: 'hsl(273.5,86.9%,21%)',
    },
  ],
  fuchsia: [
    {
      scale: 50,
      hex: '#fdf4ff',
      rgb: 'rgb(253,244,255)',
      hsl: 'hsl(289.1,100%,97.8%)',
    },
    {
      scale: 100,
      hex: '#fae8ff',
      rgb: 'rgb(250,232,255)',
      hsl: 'hsl(287,100%,95.5%)',
    },
    {
      scale: 200,
      hex: '#f5d0fe',
      rgb: 'rgb(245,208,254)',
      hsl: 'hsl(288.3,95.8%,90.6%)',
    },
    {
      scale: 300,
      hex: '#f0abfc',
      rgb: 'rgb(240,171,252)',
      hsl: 'hsl(291.1,93.1%,82.9%)',
    },
    {
      scale: 400,
      hex: '#e879f9',
      rgb: 'rgb(232,121,249)',
      hsl: 'hsl(292,91.4%,72.5%)',
    },
    {
      scale: 500,
      hex: '#d946ef',
      rgb: 'rgb(217,70,239)',
      hsl: 'hsl(292.2,84.1%,60.6%)',
    },
    {
      scale: 600,
      hex: '#c026d3',
      rgb: 'rgb(192,38,211)',
      hsl: 'hsl(293.4,69.5%,48.8%)',
    },
    {
      scale: 700,
      hex: '#a21caf',
      rgb: 'rgb(162,28,175)',
      hsl: 'hsl(294.7,72.4%,39.8%)',
    },
    {
      scale: 800,
      hex: '#86198f',
      rgb: 'rgb(134,25,143)',
      hsl: 'hsl(295.4,70.2%,32.9%)',
    },
    {
      scale: 900,
      hex: '#701a75',
      rgb: 'rgb(112,26,117)',
      hsl: 'hsl(296.7,63.6%,28%)',
    },
    {
      scale: 950,
      hex: '#4a044e',
      rgb: 'rgb(74,4,78)',
      hsl: 'hsl(296.8,90.2%,16.1%)',
    },
  ],
  pink: [
    {
      scale: 50,
      hex: '#fdf2f8',
      rgb: 'rgb(253,242,248)',
      hsl: 'hsl(327.3,73.3%,97.1%)',
    },
    {
      scale: 100,
      hex: '#fce7f3',
      rgb: 'rgb(252,231,243)',
      hsl: 'hsl(325.7,77.8%,94.7%)',
    },
    {
      scale: 200,
      hex: '#fbcfe8',
      rgb: 'rgb(251,207,232)',
      hsl: 'hsl(325.9,84.6%,89.8%)',
    },
    {
      scale: 300,
      hex: '#f9a8d4',
      rgb: 'rgb(249,168,212)',
      hsl: 'hsl(327.4,87.1%,81.8%)',
    },
    {
      scale: 400,
      hex: '#f472b6',
      rgb: 'rgb(244,114,182)',
      hsl: 'hsl(328.6,85.5%,70.2%)',
    },
    {
      scale: 500,
      hex: '#ec4899',
      rgb: 'rgb(236,72,153)',
      hsl: 'hsl(330.4,81.2%,60.4%)',
    },
    {
      scale: 600,
      hex: '#db2777',
      rgb: 'rgb(219,39,119)',
      hsl: 'hsl(333.3,71.4%,50.6%)',
    },
    {
      scale: 700,
      hex: '#be185d',
      rgb: 'rgb(190,24,93)',
      hsl: 'hsl(335.1,77.6%,42%)',
    },
    {
      scale: 800,
      hex: '#9d174d',
      rgb: 'rgb(157,23,77)',
      hsl: 'hsl(335.8,74.4%,35.3%)',
    },
    {
      scale: 900,
      hex: '#831843',
      rgb: 'rgb(131,24,67)',
      hsl: 'hsl(335.9,69%,30.4%)',
    },
    {
      scale: 950,
      hex: '#500724',
      rgb: 'rgb(80,7,36)',
      hsl: 'hsl(336.2,83.9%,17.1%)',
    },
  ],
  rose: [
    {
      scale: 50,
      hex: '#fff1f2',
      rgb: 'rgb(255,241,242)',
      hsl: 'hsl(355.7,100%,97.3%)',
    },
    {
      scale: 100,
      hex: '#ffe4e6',
      rgb: 'rgb(255,228,230)',
      hsl: 'hsl(355.6,100%,94.7%)',
    },
    {
      scale: 200,
      hex: '#fecdd3',
      rgb: 'rgb(254,205,211)',
      hsl: 'hsl(352.7,96.1%,90%)',
    },
    {
      scale: 300,
      hex: '#fda4af',
      rgb: 'rgb(253,164,175)',
      hsl: 'hsl(352.6,95.7%,81.8%)',
    },
    {
      scale: 400,
      hex: '#fb7185',
      rgb: 'rgb(251,113,133)',
      hsl: 'hsl(351.3,94.5%,71.4%)',
    },
    {
      scale: 500,
      hex: '#f43f5e',
      rgb: 'rgb(244,63,94)',
      hsl: 'hsl(349.7,89.2%,60.2%)',
    },
    {
      scale: 600,
      hex: '#e11d48',
      rgb: 'rgb(225,29,72)',
      hsl: 'hsl(346.8,77.2%,49.8%)',
    },
    {
      scale: 700,
      hex: '#be123c',
      rgb: 'rgb(190,18,60)',
      hsl: 'hsl(345.3,82.7%,40.8%)',
    },
    {
      scale: 800,
      hex: '#9f1239',
      rgb: 'rgb(159,18,57)',
      hsl: 'hsl(343.4,79.7%,34.7%)',
    },
    {
      scale: 900,
      hex: '#881337',
      rgb: 'rgb(136,19,55)',
      hsl: 'hsl(341.5,75.5%,30.4%)',
    },
    {
      scale: 950,
      hex: '#4c0519',
      rgb: 'rgb(76,5,25)',
      hsl: 'hsl(343.1,87.7%,15.9%)',
    },
  ],
}
